<template>
  <div>
    <!-- Header Section -->
    <div class="portal_header_section">
      <b-container>
        <b-row>
          <b-col>
            <div class="portal_header_wrapper">
              <div class="portal_navbar">
                <div class="logo_wrapper">
                  <a href="javascript:">
                    <div class="govt_logo">
                      <router-link to="/portal/home"><img src="../../../assets/images/logo.png" width="46" alt="" /></router-link>
                    </div>
                  </a>
                  <a href="javascript:">
                    <div class="app_logo">
                      <router-link to="/portal/home"><img :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portal/headeroriginal/' + header.logo" alt="Image Not Found"></router-link>
                      <div class="slogan_wrapper">
                        <router-link to="/portal/home"><p class="name">{{ ($i18n.locale === 'bn') ? header.title_bn : header.title }}</p></router-link>
                        <small class="daylog">{{ ($i18n.locale === 'bn') ? header.description_bn : header.description }}</small>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- <img src="../../../assets/images/mujib_100.png" class="d-block d-sm-none" width="68" alt=""/> -->
              </div>
              <div class="menu_wrapper">
                <div class="btn-group btn-group-sm">
                    <button type="button" class="btn btn_lang" @click="changeLang()">
                      <i class="fas fa-globe"></i>
                      <span>{{ langLabel }}</span>
                    </button>
                    <router-link to="/auth/login" class="btn login_nav_item animated pulse"><i class="fas fa-sign-in-alt"></i>{{ $t('globalTrans.login') }}</router-link>
                </div>
                <!-- <img src="../../../assets/images/mujib_100.png" class="d-none d-sm-inline-block" width="90" alt=""/> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Search v-if="$route.name === 'portal.home'" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '../../../config/api_config'
import Search from './home/Search'
import commonMixin from '@/mixins/dropdown-common-portal'

export default {
  mixins: [commonMixin],
  components: {
    Search
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      name: ''
    }
  },
  computed: {
    header () {
      return this.$store.state.Portal.header
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    langLabel: function () {
      return this.$i18n.locale === 'en' ? 'বাংলা' : 'English'
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeCommonDropdown({ value: newVal })
      }
    }
  },
  methods: {
    changeLang () {
      this.$i18n.locale = this.$i18n.locale === 'bn' ? 'en' : 'bn'
      this.setLocale(this.$i18n.locale)
    },
    searchService () {
      const search = {
        org_id: 0,
        customer_type_id: 0,
        service_category_id: 0,
        name: ''
      }
      this.$store.dispatch('Portal/mutateSearch', search)
    },
    async loadData () {
      this.$store.dispatch('Portal/mutatePortalLoad', true)
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/header')
      this.$store.dispatch('Portal/mutatePortalLoad', false)
      if (result.success) {
        this.$store.dispatch('Portal/mutateHeader', result.data)
      }
    },
    ...mapActions({
      setLocale: 'Setting/setLocale',
      localizeCommonDropdown: 'Portal/localizeCommonDropdown'
    })
  }
}
</script>
<style scoped>
  .animated {
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
  @keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
  }
  .pulse {
    animation-name: pulse;
    animation-duration: 1s;
  }
</style>
