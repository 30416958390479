<template>
    <div class="footer_section">
        <div class="main_link_wrapper">
            <b-container>
                <b-row>
                    <b-col sm="3" class="link_col">
                        <div class="link_item">
                            <b-list-group>
                                <router-link to="/portal/home" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.home') }}</router-link>
                                <router-link to="/portal/policy" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.policy') }}</router-link>
                                <router-link to="/portal/terms" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.terms') }}</router-link>
                                <!-- <router-link to="/portal/home" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.crop_paste_museum') }}</router-link> -->
                                <!-- <b-list-group-item href="javascript:"><i class="fas fa-angle-right"></i> {{ $t('portal.communication') }}</b-list-group-item> -->
                            </b-list-group>
                        </div>
                    </b-col>
                    <b-col sm="3" class="link_col">
                        <div class="link_item">
                            <b-list-group>
                                <!-- <router-link to="/portal/home" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.e_services') }}</router-link> -->
                                <router-link to="/portal/notice" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.notice') }}</router-link>
                                <router-link to="/portal/faq" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.Q_A') }}</router-link>
                                <router-link to="/portal/news" class="list-group-item list-group-item-action"><i class="fas fa-angle-right"></i> {{ $t('portal.agriculture_news') }}</router-link>
                            </b-list-group>
                        </div>
                    </b-col>
                    <b-col sm="3">
                        <div class="help_center">
                            <h4 class="title">{{ $t('portal.emergency') }}</h4>
                            <p class="sub_title">{{ $t('portal.nearest_agricultural_office') }}</p>
                            <div>
                                <a href="tel: 3331">
                                    <i class="fas fa-mobile-alt help_call"></i>
                                    <span class="help_number">{{ $n(header.helpline, { useGrouping: false }) }} <span style="font-size: 1rem">{{ $t('portal.portal_or') }}</span> {{ $n(16123, { useGrouping: false }) }}</span>
                                </a>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="3">
                        <div class="help_center">
                            <h4 class="title">{{ $t('portal.emergency') }}</h4>
                            <p class="sub_title mb-2">{{ $t('portal.portal_office') }}</p>
                            <div>
                                <a href="tel: 01711378181">
                                    <i class="fas fa-mobile-alt help_call"></i>
                                    <span class="help_number">{{ $t('portal.portal_emergency_no') }}</span>
                                </a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="footer_top_wrapper">
            <b-container>
                <b-row>
                    <b-col sm="4">
                        <div class="footer_item">
                            <h5 class="title">{{ $t('portal.paning_implementation') }}</h5>
                            <a target="_blank" href="http://www.moa.gov.bd/">
                                <img src="../../../assets/images/portal/bd-govt-logo.png" class="mt-1" width="70" alt="">
                                <h5 class="mt-1 mb-0">কৃষি মন্ত্রণালয়</h5>
                            </a>
                        </div>
                    </b-col>
                    <b-col sm="4">
                        <div class="footer_item footer_item_border">
                            <h5 class="title">{{ $t('portal.with_technical_assistance') }}</h5>
                                <!-- <a target="_blank" href="https://a2i.gov.bd"><img src="../../../assets/images/portal/a2i-logo.png" class="mr-2" width="90" alt=""></a> -->
                                <a target="_blank" href="https://ictd.gov.bd"><img src="../../../assets/images/portal/ict-division-logo.png" width="90" alt=""></a>
                        </div>
                    </b-col>
                    <b-col sm="4">
                        <div class="footer_item mob_itm">
                            <h5 class="title mb-4">{{ $t('portal.system_development') }}</h5>
                            <a target="_blank" href="https://www.syntechbd.com/">
                                <img src="../../../assets/images/portal/syntech-logo.png" width="214" alt="">
                            </a>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="footer_bottom_wrapper">
            <b-container>
                <b-row class="mob_row">
                    <b-col sm="6">
                        <div class="copy_right_wrapper">
                            <p class="mb-0">&copy; {{ $t('portal.footer_text') }}</p>
                        </div>
                    </b-col>
                    <b-col sm="6">
                        <div v-if="loading">Loading...</div>
                        <div v-else class="social_wrapper">
                            <a href="https://www.facebook.com/people/MoA-Service-Portal/100085566040997/" target="_blank" class="social facebook"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://www.youtube.com/watch?v=3_EvhevVCMY&list=PLIA5FKnrbyDVDlARReJ_gLVg2oicnxC9I" target="_blank" class="social pinterest"><i class="fab fa-youtube"></i></a>
                            <a href="mailto: moa.idsdp@gmail.com" class="social envelope"><i class="fas fa-envelope"></i></a>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- Messenger Chat plugin Code -->
        <div id="fb-root"></div>

        <!-- Your Chat plugin code -->
        <div id="fb-customer-chat" class="fb-customerchat">
        </div>
    </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../config/api_config'
export default {
  data () {
    return {
        loading: false,
        data: {}
    }
  },
  computed: {
    header () {
      return this.$store.state.Portal.header
    }
  },
  created () {
      this.loadData()
  },
    mounted () {
        // this.messengerLoad()
    },
  methods: {
    messengerLoad () {
      /* eslint-disable */
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "110061848512194");
      chatbox.setAttribute("attribution", "biz_inbox");

      window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v14.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      /* eslint-enable */
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/footer')
      this.loading = false
      if (result.success) {
          this.data = result.data
      }
    }
  }
}
</script>
<style scoped>
    .footer_section .main_link_wrapper {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-image: url('../../../assets/images/portal/border.png') 30 round;
    }
</style>
