<template>
    <!-- Search Bar Section -->
    <div class="portal_search_section">
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-navbar toggleable="lg" class="portal_navbar">
              <b-navbar-nav href="#" class="d-none d-sm-block">
                <div class="help_line">
                  <i class="fas fa-mobile-alt mobile"></i>
                  <span class="help_number_wrapper">
                    <span class="help_number">{{ $n(header.helpline, { useGrouping: false }) }} <span style="font-size: 1rem;">{{ $t('portal.portal_or') }}</span> {{ $n(16123, { useGrouping: false }) }}</span>
                    <span class="help_title">{{ $t('portal.helpline') }}</span>
                  </span>
                </div>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto">
                <!-- Searching  -->
                <b-nav-form class="main-searchbar-wrapper">
                  <b-form-input
                    autocomplete="off"
                    size="sm"
                    v-model="name"
                    @keypress.enter="$event.preventDefault()"
                    class="mr-sm-2 portal_searchbar"
                    :placeholder="$t('portal.find_service')"
                  ></b-form-input>
                  <img @click="searchService()" src="../../../../assets/images/search-bar.png" class="search_icon" alt="Search Icon" />
                  <div v-if="name !== ''" class="search-dropdown-wrapper" style="width: 100%">
                    <ul v-if="!searching">
                      <li v-for="(service, index) in services" :key="index">
                        <router-link :to="`/portal/service-details?service_type=allService&service_id=` + service.id" :title="$i18n.locale == 'bn' ? service.name_bn : service.name">
                          <i class="fas fa-history"></i> {{ $i18n.locale == 'bn' ? service.name_bn : service.name }}
                        </router-link>
                      </li>
                    </ul>
                    <div v-if="services.length > 0 && searching" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                        <h6>{{ $i18n.locale === 'en' ? 'Loading...' : 'লোড হচ্ছে...' }}</h6>
                    </div>
                    <div v-if="!services.length" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                        <h6 v-if="searching">{{ $i18n.locale === 'en' ? 'Loading...' : 'লোড হচ্ছে...' }}</h6>
                        <h6 v-else>{{ $t('globalTrans.noDataFound') }}</h6>
                    </div>
                  </div>
                </b-nav-form>
                <!-- End Searching  -->
                <b-nav-item href="javascript:" class="d-none d-sm-block">
                  <div class="help_line">
                    <div class="help_number_wrapper">
                      <b-skeleton v-if="loading" animation="wave" width="100%"></b-skeleton>
                      <span v-else class="help_number">{{ $n(total_user * 10) }}</span>
                      <span class="help_title">{{ $t('portal.registered_customer') }}</span>
                    </div>
                  </div>
                </b-nav-item>
                <!-- <b-nav-item href="javascript:" class="port_line d-none d-sm-block">
                  <div class="help_line">
                    <div class="help_number_wrapper">
                      <b-skeleton v-if="loading" animation="wave" width="100%"></b-skeleton>
                      <span v-else class="help_number">{{ $n(serve_user) }}</span>
                      <span class="help_title">{{ $t('portal.received_service') }}</span>
                    </div>
                  </div>
                </b-nav-item> -->
              </b-navbar-nav>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>
<script>
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
export default {
  data () {
    return {
      name: '',
      total_user: 0,
      serve_user: 0,
      loading: false,
      searching: false,
      awaitingSearch: false,
      services: []
    }
  },
  computed: {
    header () {
      return this.$store.state.Portal.header
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    name: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.searchService()
          this.awaitingSearch = false
        }, 1200) // 1.2 sec delay
      }
      this.awaitingSearch = true
    }
  },
  methods: {
    async searchService () {
      const search = {
        org_id: 0,
        customer_type_id: 0,
        service_category_id: 0,
        name: this.name
      }
      this.searching = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service-searching', search)
      if (result.success) {
        this.services = result.data
      }
      this.searching = false
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(authServiceBaseUrl, '/auth/portal/get-register-users')
      this.loading = false
      if (result.success) {
        this.total_user = result.data
        const tmpUser = ((parseInt(result.data) * 10) * 75) / 100
        this.serve_user = parseInt(tmpUser)
      }
    }
  }
}
</script>
